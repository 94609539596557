@import './variables.scss';

@import-normalize;

html,
body,
div {
    margin: 0;
    padding: 0;

    font-family: 'Raleway', sans-serif;
}

html {
    font-size: 21px;

    @media (max-width: 425px) {
        font-size: 16px;
    }

    @media (orientation: landscape) and (max-width: 600px) {
        font-size: 16px;
    }
}
